body.user-profile-page {
  #zone-content-wrapper {
    padding-bottom: 0;

    #zone-content {
      display: flex;
      flex-direction: column;
      background-color: transparent;

      #region-content .region-inner {
        padding: 0;
      }
    }
  }

  // Block titles
  h2.block-title {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: normal;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .zone-suffix-two-wrapper {
    padding-top: 20px;
  }

  // Dashboard - left
  .block-sgta-user-details-dashboard {
    position: relative;

    .view-sgta-user-details {
      padding-bottom: 0;

      .view-content {
        justify-content: flex-start;
      }

      .wrapper {
        margin-top: 10px;
        margin-bottom: 20px;

        .label {
          display: inline-block;
          width: 150px;
          font-size: 16px;
          font-weight: 400;
          color: $grey2;
        }

        .value {
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          color: $grey2;
        }
      }

      .edit-link {
        a {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  // Dashboard - right
  .block-sgta-sgta-db-quicklinks {

    .button a {
      display: inline-block;
    }

    .button.last {
      margin: 0 0 20px;
    }
  }

  // Region background colours
  .zone-suffix-two-wrapper,
  .zone-suffix-three-wrapper {
    background: $offwhite;
  }

  // Region speciific styles
  .zone-suffix-three-wrapper {
    .view-sgta-user-applications {
      border-bottom: none;
    }
  }
}
