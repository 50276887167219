.field-collection-container,
.field-collection-view {
  border-bottom: 0;
  margin: 0;
  padding: 0;
}

// Testimonial
.block-technical-academy-testimonial {
  padding-top: 45px;

  .block-title {
    font-size: 28px;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .field-name-field-quote {

    > .field-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      //
      > .field-item {
        flex: 0 0 260px;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: $white;
        margin-right: 20px;
        margin-bottom: 40px;

        .field-collection-view {
          margin: 0;
          padding: 20px 19px 15px;
        }

        .content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      > .field-item:last-of-type {
        margin-right: 0;
      }
    }

    .field-name-field-quote-name {
      flex: 1;
      font-size: 21px;
      font-weight: 700;
      color: $black;
      padding-right: 15px;
    }

    .field-name-field-quote-company {
      flex: 1;
      font-size: 16px;
      font-weight: 400;
      line-height: 39px;
      color: $grey2;
      text-align: right;
    }

    .field-name-field-quote-text {
      flex: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.63;
      color: $grey2;
      margin-top: 15px;
    }
  }
}

.individual-course {
  .block.block-sgta-courses-testimonials {
    padding-bottom: 25px;
  }
}

.block.block-sgta-courses-testimonials {
  padding-top: 45px;

  .block-title {
    text-align: center;
    margin-bottom: 40px;
  }

  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > .views-row {
      flex: 0 0 260px;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: $white;
      margin: 0 10px 40px;
      padding: 20px 19px 15px;
    }

    //> .views-row-last {
    //  margin-right: 0;
    //}
  }

  .views-field-field-testimonial-name {
    flex: 1;
    font-size: 21px;
    font-weight: 700;
    color: $black;
  }

  .views-field-field-testimonial-content {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.63;
    color: $grey2;
    margin-top: 15px;
  }

  //.field-name-field-quote-text {
  //  flex: 100%;
  //  font-size: 16px;
  //  font-weight: 400;
  //  line-height: 1.63;
  //  color: $grey2;
  //  margin-top: 15px;
  //}
}

// Location
.block-technical-academy-location {
  .block-title {
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .field-name-field-multi-text {
    > .field-items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      > .field-item {
        flex: 0 1 100%;
        text-align: center;
        padding: 0 25px;
        position: relative;

        &:first-of-type {
          margin-bottom: 30px;
        }

        &:last-of-type:before {
          display: none;
        }

        .field-collection-view {
          margin: 0;
          padding: 0;
        }

        .field-name-field-title {
          font-size: 21px;
          font-weight: 700;
          color: $black;
        }

        .field-name-field-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.63;
          color: $grey2;
          margin-bottom: 20px;
        }

        .field-name-field-link a {
          display: inline-block;
          font-size: 16px;
          border: 2px solid $orange;
          line-height: 19px;
          transition-duration: .2s;
          border-radius: 4px;
          background: $orange;
          color: #fff !important;
          padding: 15px 20px;
          font-weight: 400;

          &:hover {
            background: #ff805e;
            text-decoration: none;
          }
        }
      }
    }
  }
}

// Team Bean
.block-bean-technical-academy-team {
  padding-top: 45px;

  .block-title {
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .field-collection-container {
    margin-bottom: 75px;
  }

  .field-name-field-team-details {
    > .field-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .field-item {
        width: 240px;
        //flex: 0 1 240px;
        margin-right: 0;

        .field-collection-view {
          padding: 0;
          margin: 0;
        }
      }
    }

    .field-name-field-team-image img {
      width: 100%;
      max-width: 240px;
      height: 115px;
    }

    .field-name-field-team-title {
      min-height: 105px;
      padding: 20px 25px;
      background: $offwhite;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

//Facebook bean
#block-bean-header-one-facebook {
  display: none;
  text-align: left;
}

//Main menu trigger bean
#block-bean-mobile-main-menu-trigger {
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
  float: left;
  &:hover {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
  }
  #main-menu-trigger {
    padding-top: 30px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Ubuntu' !important;
    font-weight: 500;
    font-size: 12px;
    color: $grey;
    background-image: url('../images/icon-menu.png');
    background-position: center top;
    background-repeat: no-repeat;
  }
}

//Website logo block
#block-delta-blocks-logo {
  display: inline-block;
}

//Fake search bean
#block-bean-header-one-search-icon {
  display: block;
  float: right;
  width: 24px;
  height: 24px;
  margin-left: 0px;
  margin-top: 5px;
}

//Breadcrumbs
#block-delta-blocks-breadcrumb,
body.front #block-delta-blocks-breadcrumb {
  display: none;
}

//Bean 08 - Title Text & Images
.bean-bean-08 {
  .field-name-field-b08-text {
    @include indent;
    font-weight: 500;
  }
}

//Technical Academy (Bean 08)
.block-bean-academy-who-we-are,
.block-bean-careers-text-block,
.block-bean-charity-partners-text-block {
  margin-top: 40px;
  h2.block-title {
    font-size: 24px;
    margin-bottom: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }
}

//Location Bean (Bean 03)
.bean-bean-03 {
  .field-name-field-b03-address {
    margin-left: 40px;
    p {
      color: $lblue;
      font-weight: 500;
    }
  }
  .field-name-field-b03-link {
    a {
      display: inline-block;
      margin-bottom: 20px;
      padding: 8px 20px;
      font-weight: 300;
      background-color: $navy;
      @include trans;
      &:hover {
        color: $white !important;
        text-decoration: none;
      }
    }
  }
  .field-name-field-b03-telephone,
  .field-name-field-b03-email {
    .field-item {
      padding-left: 30px;
      margin-left: 10px;
      margin-bottom: 8px;
      color: $white;
      font-weight: 300;
      background-repeat: no-repeat;
      background-position: left center;
      a {
        color: $white !important;
        font-weight: 300;
        @include trans;
        &:hover {
          color: $orange !important;
          text-decoration: none;
        }
      }
    }
  }
  .field-name-field-b03-telephone .field-item {
    background-image: url('../images/icon-tel-20-blue.png');
    letter-spacing: 0.05em;
  }
  .field-name-field-b03-email .field-item {
    background-image: url('../images/icon-mail-20-blue.png');
  }
}

.block-bean-location-kilcalberry,
.block-bean-location-kingscourt {
  width: auto;
  float: none;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: $blue;
  h2.block-title {
    font-size: 24px;
    margin-bottom: 20px;
    padding: 40px 0 40px 100px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    border-bottom: 1px solid $mblue;
    color: $white;
    background-image: url('../images/icon-location-80.png');
    background-position: left center;
    background-repeat: no-repeat;
  }
}

//Bean 04
.bean-bean-04 {
  .field-name-field-b04-text {
    @include indent;
    color: $blue;
  }
  .field-name-field-b04-telephone {
    margin-left: 20px;
    color: $blue;
  }
  .field-name-field-b04-email {
    margin-left: 20px;
  }
}

//Events Intro Bean (Bean 01)
.block-bean-events-side-text {
  margin-top: 40px;
  h2.block-title {
    font-size: 24px;
    margin-bottom: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }
  .field-name-field-content {
    .field-item {
      @include indent;
      font-weight: 500;
    }
  }
}

//Footer: Block Titles
.block-bean-footer-three-who-we-are,
.block-bean-footer-three-social-media {
  h2 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.05em;
  }
}

//Footer: Social Media Block
.block-bean-footer-three-social-media {
  text-align: left;
  ul.social {
    float: left;
    li {
      list-style: none;
      float: left;
      width: 40px;
      height: 40px;
      overflow: hidden;
      a {
        display: block;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center top;
        &:hover {
          background-position: center bottom;
        }
        &.li {
          background-image: url('../images/soc-li-40.png')
        }
        &.yt {
          background-image: url('../images/soc-yt-40.png');
        }
        &.twt {
          background-image: url('../images/soc-twt-40.png');
        }
        &.fb {
          background-image: url('../images/soc-fb-40.png');
        }
      }
    }
  }
}

//Contact Info Block
.block-bean-contact-info-block {
  padding: 30px 0;
  h2.block-title {
    color: $navy;
    margin-bottom: 20px;
    letter-spacing: 0.02em;
  }
  .field-name-field-b04-text,
  .field-name-field-b04-telephone,
  .field-name-field-b04-email {
    font-weight: 500;
  }
  .field-name-field-b04-telephone,
  .field-name-field-b04-email {
    .field-item {
      margin-bottom: 8px;
      padding-left: 28px;
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
  .field-name-field-b04-telephone {
    .field-item {
      background-image: url('../images/icon-tel-20.png');
    }
  }
  .field-name-field-b04-email {
    .field-item {
      background-image: url('../images/icon-mail-20.png');
      a {
        color: $blue !important;
        @include trans;
        &:hover {
          color: $orange !important;
          text-decoration: none;
        }
      }
    }
  }
}

//Contact Webform
.block-webform-client-block-16 {
  margin: 30px 0;
  .form-item {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0;
    float: left;
    label {
      margin-bottom: 5px;
      color: $navy;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.02em;
    }
    input,
    select {
      width: 100%;
      border: 0 none;
      height: 36px;
      &:focus {
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      }
    }
    textarea {
      border: 0 none;
      &:focus {
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      }
    }
  }
  .form-actions {
    clear: both;
    text-align: right;
    margin-right: 5%;
    input[type="submit"] {
      background: $orange !important;
      background-colour: $orange !important;
      text-shadow: none;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      color: $white;
      letter-spacing: 0.05em;
      font-family: 'Ubuntu' !important;
      text-transform: uppercase;
      border: 0 none;
      padding: 10px 24px;
      @include trans;
      &:hover {
        background: $white !important;
        background-colour: $white !important;
        color: $orange;
      }
    }
  }
  .form-item.webform-component--website-privacy {
    float: right;
    & > label {
      display: none;
    }
    .form-type-checkbox {
      margin-left: 5px;
      width: auto;
      input,
      label {
        width: auto;
        display: inline;
      }
      label {
        padding-left: 5px;
      }
    }
  }
}

//Search Tabs
.search-tabs {
  margin-top: 40px;
  margin-bottom: -1px;
  .search-tab {
    display: block;
    float: left;
    font-weight: 700;
    border-top: 1px solid $lgrey;
    border-left: 1px solid $lgrey;
    color: $blue;
    font-size: 14px;
    a {
      display: block;
      padding: 10px 15px;
      background-color: $lblue;
      color: $mblue !important;
      @include trans;
      &:hover {
        background-color: darken($lblue, 5%);
        text-decoration: none;
      }
    }
    &.tab-global-search {
      border-right: 1px solid $lgrey;
    }
    &.active {
      background-color: $white;
      padding: 10px 15px;
    }
  }
}

// -- Search all jobs careers bean
.search-all-jobs-wrapper {
  .search-all-jobs {
    float: left;
    position: relative;
    margin-bottom: 40px;
    background-color: $offwhite;
    a {
      display: block;
      padding: 20px 55px 15px 20px;
      font-size: 21px;
      color: $black !important;
      text-transform: uppercase;
      @include trans;
      &:hover {
        color: $orange !important;
        text-decoration: none;
      }
      .arrow {
        margin-left: 15px;
        background: $orange url(../images/right.png) no-repeat center center;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 30px;
      }
    }
  }
}

// sgta blocks
#block-sgta-sgta-db-course-materials {
  h2.block-title {
    font-size: 18px;
    font-weight: 500;
  }
}

#materials-popup {
  &, & * {
    z-index: 150;
  }
  display: none;
  background-color: rgba(128, 128, 128, 0.8);
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .message-wrapper {
    width: 80%;
    max-width: 80%;
    position: relative;
    margin: auto;
    margin-top: 160px;
    border: 2px solid $grey;
    @include radius4;
    padding: 15px 20px;
    background-color: $offwhite;
    display: inline-block;
    .message {
      padding: 5px 0 20px 0;
      font-size: 16px;
    }
    .accept {
      cursor: pointer;
      font-size: 14px;
      background: none transparent no-repeat;
      border: 2px solid $orange;
      text-shadow: none;
      font-weight: normal;
      letter-spacing: 1px;
      font-family: 'Roboto';
      font-weight: normal;
      line-height: 15px;

      @include trans;
      @include radius4;
      background-color: $orange;
      color: $white !important;
      padding: 15px 20px;
      text-transform: uppercase;
      display: block;
      &:hover {
        background-color: lighten($orange, 10%);
        color: $white !important;
        text-decoration: none;
      }
    }
  }
}

#block-sgta-sgta-dashboard-quicklinks {
  padding-top: 20px;
  padding-left: 15px;
  .form-item {
    width: 60%;
    min-width: 200px;
  }
}

#block-sgta-sgta-course-apply-one {
  padding: 20px 0 50px 50px;
  box-sizing: border-box;
  .button {
    min-width: 200px;
  }
}

#block-sgta-sgta-course-apply-two {
  padding-top: 20px;
  padding-left: 15px;
  .form-item {
    width: 80%;
    min-width: 200px;
  }
}

#sgta-forms-profile-courses-vote-form {
  label {
    font-weight: 200;
  }
  .bold-text {
    font-weight: bold;
  }
}

#block-sgta-sgta-academy-apply {
  .block-inner {
    //h2.block-title {
    //  text-transform: uppercase;
    //  letter-spacing: 1px;
    //  font-size: 22px;
    //  font-weight: 400;
    //  display: block;
    //  padding: 10px 0px 10px 30px;
    //  box-sizing: border-box;
    //  background: transparent url(../images/icon-register.png) no-repeat left center;
    //  margin-bottom: 20px;
    //}

    & > .content {
      padding-left: 30px;
      padding-right: 15px;
      box-sizing: border-box;
      .fieldset-legend {
        font-family: Ubuntu;
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $navy;
        margin-bottom: 10px;
        display: inline-block;
      }
      .form-item {
        margin-bottom: 30px;
        label,
        .description {
          font-weight: 300;
          font-size: 13px;
          margin-bottom: 8px;
        }
        label {
          text-transform: uppercase;
        }
        .description {
          margin-left: 0;
        }
        .form-text,
        .form-select,
        .form-textarea {
          border: solid 2px lighten($lgrey, 10%);
          background: $offwhite;
          padding: 5px 10px;
          color: $grey;
          box-sizing: border-box;
          width: 70%;
          min-width: 250px;
          height: 35px;
        }

        .form-textarea {
          height: 90px;
        }
      }
    }
  }
}

#block-sgta-sgta-academy-apply-multiple-link {
  padding-top: 20px;
  padding-left: 15px;
  width: 80%;
  min-width: 200px;
}

//#block-sgta-sgta-academy-bespoke {
//  padding-top: 20px;
//  padding-left: 15px;
//  width: 80%;
//  min-width: 200px;
//  h2.block-title {
//    display: block;
//    color: $navy;
//    text-transform: uppercase;
//    padding-bottom: 5px;
//    //border-bottom: 2px solid $lgrey;
//  }
//  //.form-item {
//  //}
//}

.sgta {
  #block-user-login {
    padding-top: 20px;
    padding-left: 15px;
    padding-bottom: 40px;
    width: 80%;
    min-width: 200px;
    h2.block-title {
      display: block;
      color: $navy;
      text-transform: uppercase;
      padding-bottom: 5px;
      border-bottom: 2px solid $lgrey;
    }
    .form-item {
      margin-bottom: 10px;
      label {
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        color: $grey;
        letter-spacing: 1px;
        padding-bottom: 5px;
      }
      .form-text {
        border: 2px solid $lgrey;
        height: auto;
        padding: 10px 15px;
        width: 90%;
        min-width: 200px;
      }

    }
    .form-actions input {
      background: none transparent no-repeat;
      border: 0px transparent none;
      text-shadow: none;
      font-weight: normal;
      letter-spacing: 1px;
      display: inline-block;
      width: 50%;
      min-width: 200px;
      font-size: 14px;

      @include trans;
      @include radius4;
      background-color: $orange;
      color: $white !important;
      padding: 15px 20px;
      text-transform: uppercase;
      &:hover {
        background-color: lighten($orange, 10%);
        color: $white !important;
        text-decoration: none;
      }
    }
  }
}



#block-sgta-sgta-db-course-confirm {
  .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .form-item {
    margin-top: 0;
    margin-bottom: 0;
  }
  .event-status {
    font-size: 14px;
    color: $grey;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .event-date {
    font-size: 20px;
    color: $orange;
    letter-spacing: 1px;
  }
}

body.context-403 {
  #block-user-login {
    padding: 20px;
    background-color: $offwhite;
  }
}

#block-sgta-sgta-academy-strap {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 40px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;

  .block-inner {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex: 1 0 100%
    }
  }

  h2.block-title {
    display: block;
    text-transform: uppercase;
    font-size: 20px;
    color: $grey2;
    padding: 0 0 15px 0;

    svg {
      margin-bottom: -15px;
      margin-right: 10px;
    }
  }

  .block-text {
    font-size: 16px;
    font-family: 'Roboto';
    color: $grey2;
  }

  .button.orange a {
    @include radius-none;
    border: 0 none transparent;
    margin-left: 20px;
    &:hover {
      border: 0 none transparent;
      background-color: $black;
    }
  }

  .block-content-wrapper {
    display: flex;
    flex-wrap: wrap;

    .block-content-inner,
    .block-content-image-wrapper {
      flex: 1 0 100%;
      margin: 0;
    }

    .block-content-image-wrapper img {
      margin-top: 20px;
      width: 100%;
      max-width: 380px;
    }
  }
}

// Text & Video bean
.text-video-bean {
  display: flex;
  flex-direction: column;
  padding: 50px 0 60px;

  .text-video-bean__content {
    flex: 0 1 100%;

    h2 {
      font-size: 28px;
      font-weight: 400;
      letter-spacing: normal;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: $grey2;
    }
  }

  .text-video-bean__iframe {
    flex: 0 1 100%;
    min-height: 210px;
  }
}

// Stats Bean
.bean-stats-banner {
  padding: 15px 0 0;

  .field-name-field-stats-content >.field-items > .field-item {
    margin-bottom: 15px;
  }

  > .field-name-field-stats-content {
    display: flex;
    justify-content: center;

    > .field-items {
      display: flex;
    }
  }

  .field-name-field-stats-content {
    .field-items {
      .content {
        display: flex;
        align-items: center;
      }

      .field-name-field-stat-icon {
        margin-right: 18px;
      }

      .field-name-field-stat-bold-text {
        font-size: 28px;
        font-weight: 700;
        margin-right: 10px;
      }

      .field-name-field-stat-normal-text {
        font-size: 26px;
      }

      .field-name-field-stat-bold-text,
      .field-name-field-stat-normal-text {
        color: $grey2;
      }
    }
  }
}

// Link bean
.link-bean.button--orange {
  text-align: center;
  margin-bottom: 65px;

  a {
    display: inline-block;
  }
}

//
.block-sgta-course-descriptor-block .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 0;

  .form-item {
    margin: 0 0 30px;
  }

  .button {
    margin-top: 0;
    margin-bottom: 0;
  }

  .form-item:first-of-type {
    .button a {
      background: transparent;
      color: $orange !important;

      &:hover {
        color: $white !important;
        background: lighten($orange,10%);
      }
    }
  }
}