.user-profile-page .view-sgta-user-applications {
  border-bottom: 2px solid $grey8;
  padding-bottom: 20px;
  overflow: hidden;

  .sgta-card .card-date {
    justify-content: flex-start;
  }


  img {
    border-radius: 3px 3px 0 0;
  }
}

.view-sgta-user-applications,
.sgta-view {
  margin-top: 0;
  padding-bottom: 50px;

  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sgta-card {
    background: $white;
    margin: 0 0 30px;
    max-width: 370px;

    > a:hover {
      color: $grey3 !important;
      text-decoration: none;
    }

    .text-content {
      display: flex;
      flex-wrap: wrap;
      padding: 15px 20px;

      > a {
        flex: 1 0 100%;
        display: block;
        color: $grey3 !important;
        text-decoration: none;
        margin-bottom: 45px;
      }
    }

    .card-title-wrapper {
      flex: 1 0 100%;
      font-size: 16px;
      font-weight: 500;
      color: $grey3 !important;
      margin-bottom: 20px;
    }

    .card-details {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .card-date {
        color: $grey3 !important;
        justify-content: flex-start;
      }
    }

    .card-date,
    .card-cancel {
      font-size: 12px;
      font-weight: 500;
    }

    .card-date {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;

      .date-display-single,
      .date-display-range {
        margin-left: 10px;
        display: inline-block;
        color: $black !important;
      }
    }

    .card-cancel {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
      text-align: right;
      font-weight: 400;
      color: $orange;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.block-sgta-courses-listing-listing,
.view-display-id-dashboard_past {
  .sgta-card {
    .text-content {
      padding-top: 15px;

      .card-title-wrapper {
        margin-bottom: 45px;
      }
    }
  }
}

.block-sgta-courses-listing-listing {
  .sgta-card {
    .text-content {
      .card-title-wrapper {

        a {
          color: $grey3 !important;
          text-decoration: none;
        }
      }
    }
  }
}

.view-display-id-featured_course {
  .sgta-card {
    .card-title-wrapper {

      a {
        color: $grey3 !important;
        text-decoration: none;
      }
    }
  }
}



