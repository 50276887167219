.contextual-links-wrapper {
  &, & * {
    z-index: 30 !important;
  }
}

div#page.page {
  padding-top: 80px;
}

#section-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
  & > div {
    margin: auto;
  }
}

body.admin-menu #section-header {
  top: 29px;
}

.zone-header-one-wrapper {
  background-color: $offwhite;
  padding: 8px 0;
  margin-bottom: 10px;
}

.zone-header-search-wrapper {
  background-color: $grey;
  border-bottom: 3px solid $offwhite;
  input {
    border: 0 none;
    height: 40px;
    width: 100%;
    background: $white;
    &:focus {
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    }
  }
  input.form-submit {
    display: none;
  }
}

#region-prefix-three-first {
  #block-search-form-copy {
    .form-type-textfield {
      display: inline-block;
      width: 80%;
      input {
        width: 100%;
        height: 45px;
        line-height: 35px;
        padding: 5px;
        background: #fff;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        font-size: 16px;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border-radius: 0;
        border: 1px $grey solid;
      }
    }
    .form-actions {
      display: inline-block;
      width: 15%;
      background-color: $white;

      input.form-submit {
        height: 45px;
        width: 100%;
        border: 1px $grey solid;
        font-size: 0px;
        color: $white;
        padding: 0;
        margin: 0;
        background: url(../images/searchbar.png) 50% 50% no-repeat #fff;
        background-size: 23px 23px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        cursor: pointer;
        @include trans;
        &:hover {
          background-color: darken($offwhite, 5%);
        }
      }
    }
  }
}

.zone-header-one-wrapper {
  @include shadow9;
}

.zone-header-two-wrapper {
  background-color: $white;
  padding: 20px 0 20px 0;
  display: none;
  @include shadow9;
}

//Full Width Header
body.wrapper-restrict-contact #zone-prefix-two-wrapper {
  #zone-prefix-two {
    margin: 0px !important;
    width: 100% !important;
    & > .grid-24 {
      margin: 0px !important;
      width: 100% !important;
    }
    .himage-full img {
      width: 100%;
      height: auto;
    }
  }
}

.zone-prefix-one-wrapper {
  background-color: $offwhite;
  #zone-prefix-one {
    width: 100%;
    & > div {
      margin-left: 0px;
      margin-right: 0px;
      #block-views-page-elements-title,
      #block-views-sgta-user-headers-title,
      #block-views-sgta-user-headers-m-course,
      #block-views-sgta-user-headers-m-application,
      #block-views-sgta-user-headers-m-date {
        & .block-inner-wrapper {
          background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 75%); /* FF3.6+ */
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(75%, rgba(0, 0, 0, 0))); /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 75%); /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(left, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 75%); /* Opera 11.10+ */
          background: -ms-linear-gradient(left, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 75%); /* IE10+ */
          background: linear-gradient(to right, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 75%); /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1); /* IE6-9 */
          color: $white !important;
          & > .block-inner {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
        }
      }
      #block-delta-blocks-breadcrumb {
        //margin-top: 10px;
        //margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        ul.breadcrumb {
          margin: 0px;
        }
      }
    }
  }
}

body.not-front.ie-8,
body.not-front.ie-7 {
  .zone-prefix-one-wrapper {
    #zone-prefix-one {
      & > div {
        #block-views-page-elements-title,
        #block-views-sgta-user-headers-title,
        #block-views-sgta-user-headers-m-course,
        #block-views-sgta-user-headers-m-application,
        #block-views-sgta-user-headers-m-date {
          & .block-inner-wrapper {
            background: url("../images/grad-740.png");
            background-position: left top;
            background-repeat: repeat-y;
          }
        }
      }
    }
  }
}

.context-events .zone-suffix-two-wrapper {
  margin-top: 40px;
}

.context-contact .zone-prefix-two-wrapper,
.context-academy-locations .zone-prefix-two-wrapper {
  margin-top: 40px;
  background-color: $blue;
  background-image: url('../images/sg-logo-wall.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.front .zone-prefix-three-wrapper {
  background-color: $offwhite;
  padding: 30px 0;
}

.zone-content-wrapper {
  padding: 40px 0;
}

.page-search-node,
.page-gsearch {
  .zone-content-wrapper {
    padding: 0 0 40px 0;
    .zone-content {
      border: 1px solid $lgrey;
      .region-content {
        padding: 15px;
        @include boxsize;
      }
    }
  }
}

// User One-Time Login
.page-user-edit {
  #zone-content {
    padding: 30px;
    box-sizing: border-box;
    background-color: $offwhite;
    > *.grid-24 {
      margin: 0 auto;
      width: 100%;
    }
    .tabs {
      display: none;
    }
  }
  #user-profile-form {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input.password-confirm,
    input.password-field,
    select {
      margin-bottom: 10px;
      width: 100% !important;
      max-width: 400px !important;
      height: 36px;
      border: solid 2px #ebebeb;
    }
    .description {
      max-width: 620px;
    }
    .password-strength,
    .password-confirm {
      float: none;
      margin: 10px 0;
      width: 100%;
      max-width: 280px;
    }
    .password-parent,
    .confirm-parent {
      width: 100%;
      max-width: 400px;
    }
    .password-suggestions {
      ul {
        margin-left: 20px;
      }
    }
    input[type="submit"] {
      background: $orange !important;
      background-colour: $orange !important;
      text-shadow: none;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      color: $white;
      letter-spacing: 0.05em;
      font-family: 'Ubuntu' !important;
      text-transform: uppercase;
      border: 0 none;
      padding: 10px 24px;
      @include trans;
      &:hover {
        background: $white !important;
        background-colour: $white !important;
        color: $orange;
      }
    }
  }
}

body.user-profile-page {
  #zone-content {
    background-color: $offwhite;
    padding-bottom: 60px;
    #region-content {
      background-color: $white;
      margin-left: 0;
      padding-left: 10px;
      .region-inner {
        box-sizing: border-box;
        padding: 20px;
      }
      .region-content-inner {

        & > div.tabs {
          display: none;
        }

      }
    }
    #region-suffix-one-first {
      .block-views {
        margin: 20px 0;
      }
    }
  }
  &.admin-menu {
    #zone-content {
      #region-content {
        .region-content-inner > div.tabs {
          display: block;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.section-footer {
  background-color: $ftrblack;
  color: $lgrey;
  font-weight: 300;
  h2 {
    color: $offwhite;
  }
}

.zone-footer-one-wrapper {
  background-color: $lblue;
}

.zone-footer-three-wrapper {
  padding: 10px 0;
}

//EU cookie compliance
#sliding-popup {
  .popup-content {
    #popup-text {
      @include indent;
      margin-top: 10px;
      p {
        font-size: 14px;
        font-weight: 300;
        display: block;
      }

      button {
        font-weight: normal;
      }
    }
    #popup-buttons {
      button.agree-button,
      button.find-more-button {
        font-family: 'Ubuntu' !important;
        @include radius4;
      }
    }
  }
}

//Breadcrumb
ul.breadcrumb {
  margin-top: 5px;
  margin-bottom: 15px;
  padding-bottom: 0;
  li {
    margin-right: 0;
    a {
      display: inline-block;
      margin-left: 15px;
      padding: 0 0 0 25px;
      line-height: 50px;
      font-family: 'Roboto';
      color: $blue !important;
      font-weight: 500;
      font-size: 13px;
      background-image: url('../images/icon-crumb.png');
      background-position: left center;
      background-repeat: no-repeat;
      @include trans;
      &:hover {
        color: $mblue !important;
        text-decoration: none;
      }
      &.active {
        color: $blue !important;
      }
      &.active:hover {
        color: $mblue !important;
      }
    }
    &.first {
      position: relative;
      margin-right: 15px;
      a {
        position: absolute;
        margin-left: 0;
        top: 0;
        width: 18px;
        height: 19px;
        padding: 0;
        display: inline-block;
        background-image: url('../images/icon-home.png');
        font-size: 0;
      }
    }
  }
}

//Branding
.logo-img {
  img {
    width: 100%;
    max-width: 220px;
    height: auto;
  }
}

//LInks Header
#header-one-links {
  color: $grey;
  a {
    font-size: 12px;
    font-weight: 300;
    color: $grey !important;
    @include trans;
    &:hover {
      text-decoration: underline;
    }
    img {
      margin-right: 8px;
    }
  }
}

//Search Icon Header
.block-bean-header-one-search-icon {
  position: inherit;
  #block-fake-search-form {
    position: absolute;
    top: 20px;
    right: 0;
    img:hover {
      cursor: pointer;
    }
  }
}

//------ FOOTER ELEMENTS ------//
//Twitter
.block-views-twitter-block-2 {
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $grey;
}

.block-bean-footer-four-copyright {
  font-size: 12px;
  color: $grey;
}

//------ NODES ------//
h1.page-title {
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  padding-top: 20px;
  font-size: 24px;
}

h2.content-header {
  background-color: $black;
  padding: 8px 20px;
  margin-bottom: 40px;
  font-size: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: $white;
  display: inline-block;
  font-weight: 500;
}

h3.content-title {
  color: $grey;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 30px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.page-subtitle {
  @include indent;
  font-size: 16px;
  color: $white;
  font-family: 'Roboto';
  font-weight: 500;
}

.field-name-field-home-content {
  @include indent;
  font-size: 16px;
  font-weight: 300;
  padding-left: 20px;
}

//Header Logo
.header-logo {
  text-align: left;
  margin-bottom: 20px;
  img {
    //width: 100%;
    //max-width: 230px;
    //height: auto;
  }
}

.node {
  .field-name-field-content {
    margin-left: 15px;
    max-width: 880px;
    h3 {
      color: $grey;
      margin-left: -15px;
      text-transform: uppercase;
      letter-spacing: 0.01em;
    }
    h4 {
      color: $blue;
      text-transform: uppercase;
      letter-spacing: 0.01em;
    }
    figure.caption {
      float: none !important;
    }
    img.embed-image {
      float: none;
      margin-left: -15px;
      margin-right: 0;
      margin-bottom: 20px;
      width: 100% !important;
      height: auto !important;
    }
    figcaption {
      margin-left: -15px;
      padding: 5px;
      margin-right: 20px;
      border-bottom: 1px solid $lgrey;
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 300;
      clear: both;
    }
  }
}

//Search Page
h2.search-page-title {
  display: inline-block;
  margin: 30px 0;
  padding: 10px 20px;
  color: $white;
  background-color: $black;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 28px;
}

//Search Form
.search-form {
  .form-item {
    label {
      display: block;
      font-weight: 500;
      font-style: italic;
      font-size: 18px;
      margin-bottom: 10px;
      color: $grey;
    }
    input.form-text {
      width: 60%;
      height: 40px;
      border: 1px solid $lgrey;
      background-color: $offwhite;
      &:focus {
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      }
    }
  }
  input[type="submit"] {
    background: $orange !important;
    background-colour: $orange !important;
    text-shadow: none;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    color: $white;
    letter-spacing: 0.05em;
    font-family: 'Ubuntu' !important;
    text-transform: uppercase;
    border: 0 none;
    padding: 10px 24px;
    @include trans;
    &:hover {
      background: $offwhite !important;
      background-colour: $offwhite !important;
      color: $orange;
    }
  }
}

//Search Results
.search-results {
  .search-result {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid $lgrey;
    h3.title {
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      a {
        color: $black !important;
        @include trans;
        &:hover {
          color: $orange !important;
          text-decoration: none;
        }
      }
    }
    p.search-url {
      margin-bottom: 10px;
      a {
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.02em;
        color: $orange !important;
        @include trans;
        &:hover {
          color: $black !important;
          text-decoration: none;
        }
      }
    }
    p.search-snippet {
      margin-bottom: 10px;
    }
  }
}

/* Technical Academy */
.page-technical-academy-manage-academy-applications-add,
.page-technical-academy-manage-academy-dates-add {
  #zone-content .region-content-inner {
    padding: 30px;
    box-sizing: border-box;
    background-color: $offwhite;
  }
}

.page-technical-academy-manage-academy-courses-add {
  fieldset legend {
    display: inline-block;
  }
  input[type="submit"] {
    background: $orange !important;
    background-colour: $orange !important;
    border: 2px solid $orange;
    text-shadow: none;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    color: $white;
    letter-spacing: 0.05em;
    font-family: 'Ubuntu' !important;
    text-transform: uppercase;
    padding: 8px 16px;
    @include trans;
    &:hover {
      background: lighten($orange, 10%) !important;
      background-colour: lighten($orange, 10%) !important;
      border: 2px solid $orange;
      color: $white;
    }
  }
}

body.page-technical-academy-manage-academy-dates-,
body.page-technical-academy-manage-academy-applications-,
body.page-technical-academy-manage-academy-courses-,
body.node-type-course
  //body.page-node-55
{
  #zone-content {
    background-color: $offwhite;
    padding-bottom: 20px;

    #region-content {
      margin-left: 0;
      padding-left: 10px;
      background-color: $white;
      .region-inner {
        box-sizing: border-box;
        padding: 20px;
      }
    }
    #region-suffix-one-first {
      padding-bottom: 20px;
    }

  }
}

body.page-node-8 {
  #zone-content-wrapper {
    background-color: $offwhite;
  }
}

body.page-technical-academy-manage-academy-courses {
  .horizontal-tabs {
    border: 0 none transparent;
    background-color: $offwhite;
    ul.horizontal-tabs-list {
      background-color: $white;
      border: 0 transparent none;
      li {
        padding: 0;
        border: 1px $orange solid;
        border-bottom: 0 transparent none;
        border-radius: 5px 5px 0px 0px;
        -moz-border-radius: 5px 5px 0px 0px;
        -webkit-border-radius: 5px 5px 0px 0px;
        background-color: $orange;
        @include trans;
        a {
          display: block;
          padding: 15px 20px;
          background-color: transparent;
          strong {
            color: $white;
            font-weight: 400;
            letter-spacing: 1px;
          }

          text-transform: uppercase;

        }
        &:hover {
          background-color: lighten($orange, 10%);
          a {
            background-color: transparent;
          }
        }

      }
      li.selected {
        border: 1px $offwhite solid;
        border-bottom: 0 transparent none;
        background-color: $offwhite;
        a {
          strong {
            color: $navy;
            font-weight: 400;
            letter-spacing: 1px;
          }
        }
        &:hover {
          background-color: $offwhite;
          cursor: default;
          a {
            cursor: default;
          }
        }
      }
    }
  }
  .horizontal-tabs-pane {
    padding: 15px;
  }
  form#sgta-routing-manage-academy > div > div,
  form#sgta-routing-manage-academy > div > fieldset {
    background-color: $offwhite;
    padding: 10px;
    margin-top: 10px;
    legend {
      font-weight: bold;
    }
    .form-type-managed-file {
      margin-left: 15px;
      padding-left: 15px;
      border-left: black 2px solid;
    }
  }
}

body.page-technical-academy-manage-academy-dates-edit {
  .date-combo {
    .fieldset-legend {
      display: none;
    }
    .date-padding {
      padding: 0;
    }
  }
}

//------ IE FIXES ------//
.ie-7,
.ie-8 {
  //Breadcrumb
  ul.breadcrumb {
    li {
      margin-left: 16px;
    }
  }
  //Main Menu
  .block-menu-block-1 {
    ul.menu {
      li {
        a {
          padding: 6px 10px;
        }
      }
    }
  }
  //Projects & Guides
  .projects {
    .views-row {
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }
  //Events
  .events {
    .views-row {
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }

  //Courses calendar
  .calendar-calendar {
    thead,
    tbody,
    tr {
      width: 100%;
      th,
      td {
        width: 20%;
      }
    }
  }

}

.date-info-wrapper {
  margin-top: 2px;
  margin-bottom: 20px;
  padding: 15px 20px;
  display: inline-block;
  background-color: $offwhite;

  .course-date {
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: normal;

  }
  .course-title {
    font-weight: normal;
    color: $navy;
  }
}

form.sgta {
  input[type="text"],
  select {
    background-color: $white;
    border: 2px solid $lgrey;
    height: 36px;
    width: 100%;
    max-width: 400px;
    &:focus {
      border: 2px solid $orange;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  textarea {
    border: 2px solid $lgrey;
    max-width: 580px;
    &:focus {
      border: 2px solid $orange;
      box-shadow: none;
    }
  }
  .grippie {
    max-width: 580px;
  }
  fieldset.date-combo .container-inline-date .date-padding {
    padding: 10px 0;
    .form-item {
      margin-right: 10px;
    }
  }
  input[type="file"] {
    background-color: $white;
  }
}

form.sgta-register-table {
  #edit-intro-text {
    margin-bottom: 30px;
    h2 {
      font-size: 22px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    p {

    }
  }

  .ajax-progress,
  .ajax-progress-throbber {
    .message {
      display: none !important;
    }
  }
  span.red {
    color: red;
    margin-left: 4px;
  }
  .button-application-add {
    margin-top: 0;
    margin-bottom: 40px;
    input[type="submit"] {
      border-width: 3px;
    }
  }
  .button-application-save,
  .button-application-save-assign {
    float: right;
    margin-left: 20px !important;
  }
  .button-application-assign-dates {
    margin-top: 40px;
    float: right;
  }
  table {
    margin-bottom: 0;
  }
  td {
    padding: 5px 2px;
  }
  td.reg-ops-cell {
    text-align: center;
  }
  td.count-cell,
  th.count-cell {
    text-align: center;
    font-weight: bold;
    padding: 15px 8px;
    width: 10px;
    max-width: 10px;
  }
  tr.even {
    td {
      background-color: $offwhite;
    }
  }
  th {
    font-weight: bold;
    background-color: $lblue;
    padding: 15px 2px;
    &.reg-ops {
      padding-right: 10px;
    }
  }

  &, & * {
    font-size: 14px;
  }

  input[type="text"] {
    background-color: $white;
    border: 1px solid $lgrey;
    height: 36px;
    width: 100%;
    max-width: none;
  }

  select {
    background-color: $white;
    border: 1px solid $lgrey;
    height: 36px;
  }

  .reg-ops-action {
    cursor: pointer;
    font-size: 0;
    width: 30px;
    height: 30px;
    margin: auto;
    &.action-copy {
      background-image: url('../images/icon-copy-black-16.png');
      background-position: center center;
      background-repeat: no-repeat;
      &:hover {
        background-image: url('../images/icon-copy-orange-16.png');
      }
    }
    &.action-undo {
      background-image: url('../images/icon-undo-black-16.png');
      background-position: center center;
      background-repeat: no-repeat;
      &:hover {
        background-image: url('../images/icon-undo-orange-16.png');
      }
    }
    &.action-paste {
      background-image: url('../images/icon-arrowleft-black-16.png');
      background-position: center center;
      background-repeat: no-repeat;
      &:hover {
        background-image: url('../images/icon-arrowleft-orange-16.png');
      }
    }
  }

  .form-type-checkbox {
    label {
      font-weight: 300;
      text-transform: uppercase;
    }
  }
}

form.sgta-register-table-one {
  select {
    width: 90px;
    max-width: 90px;
  }
}

form.sgta-register-table-two {
  .course-cell {
    width: 395px;
    max-width: 395px;

  }
  .location-cell {
    width: 270px;
    max-width: 270px;

  }
  .date-cell {
    width: 135px;
    max-width: 135px;

  }
}

.course-application {
  .zone-suffix-two-wrapper {
    background: $offwhite;
  }

  #block-sgta-sgta-academy-apply .block-inner > .content {
    padding: 0;
  }

  #sgta-forms-apply-online-form {
    width: 100%;
    max-width: 430px;
  }

  #block-sgta-sgta-academy-apply .block-inner > .content .form-item {
    .form-select,
    .form-text,
    .form-textarea {
      width: 100%;
    }
  }
}

// CPD courses landing
.academy-courses-landing {
  .zone-suffix-three-wrapper {
    background: $offwhite;
  }
}

.prospectus-block {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 360px;
  margin: 50px auto 30px;

  .prospectus-textarea {
    font-size: 16px;
    line-height: 26px;
    color: $grey2;
    margin-bottom: 10px;
  }

  .button.grey {
    margin-bottom: 0;

    a {
      display: inline-block;

    }
  }
}

// Bespoke courses
.block-sgta-academy-bespoke {
  display: flex;
  justify-content: center;
  margin: 45px 0 50px;

  .block-inner {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 620px;
    text-align: center;

    > * {
      flex: 1 0 100%;
    }
  }

  .block-title {
    font-family: 'Ubuntu';
    font-size: 28px;
    font-weight: 400;
    letter-spacing: normal;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  .bespoke-courses {
    display: flex;
    flex-direction: column;

    .form-item {
      margin: 0;
    }

    .bespoke-courses-text {
      font-size: 16px;
      line-height: 26px;
      color: $grey2;
      margin-bottom: 30px;
    }

    .button {
      display: inline-block;
      margin: 0;
    }
  }
}

// Tech academy landing page
.tech-academy-landing {

  .zone-content-wrapper {
    padding: 0;
  }

  .region-suffix-one-first {
    .block-title {
      display: block;
      text-align: center;
      font-family: 'Ubuntu';
      font-size: 28px;
      font-weight: 400;
      letter-spacing: normal;
      color: $black;
      text-transform: uppercase;
      padding: 40px 0 25px;
    }
  }

  .zone-suffix-one-wrapper,
  .zone-suffix-three-wrapper {
    background: $offwhite;
  }

  .block-technical-academy-location {
    padding: 45px 0 55px;
  }
}

// Course page
body.individual-course {
  //.zone-prefix-one-wrapper {
  //  background: $white;
  //}

  #zone-content-wrapper #zone-content {
    background-color: transparent;
  }

  #zone-content #region-content .region-inner {
    padding: 0;
  }

  article {
    #block-views-sgta-course-details-topics h2.block-title,
    h2,
    h2.content-header {
      font-weight: 400;
      background-color: transparent;
      letter-spacing: normal;
      color: $black;
      margin-bottom: 25px;
      padding: 0;
    }
  }

  .field-name-field-content {
    margin: 0;
    padding: 0;

  }

  // sidebar
  #block-views-sgta-course-details-cost,
  #block-views-sgta-course-details-dates,
  #block-views-sgta-course-details-duration,
  #block-views-sgta-course-details-hours,
  #block-views-sgta-course-details-location {
    padding: 20px 0 0;
    background-color: transparent;

    //h2.block-title {
    //  font-size: 16px;
    //  font-weight: 500;
    //  color: $grey3;
    //  text-transform: capitalize;
    //  border-bottom: 0;
    //  margin-bottom: 10px;
    //  padding-bottom: 0;
    //}

    .content {
      padding: 0;

      .field-content {
        font-size: 16px;
        font-weight: 300;
        color: $grey2;
        padding: 0;
      }
    }
  }

  .block-sgta-course-apply-action-alt {
    margin-bottom: 30px;

    .block-title {
      margin-bottom: 20px;
    }
  }

  .views-field-field-location-map {
    padding-top: 15px;
  }
}

.individual-course,
.page-user-courses,
.user-course-details {
  .grid-8 {
    h2.block-title {
      font-size: 16px;
      font-weight: 500;
      color: $grey3;
      text-transform: capitalize;
      border-bottom: 0;
      margin-bottom: 10px;
      padding-bottom: 0;
    }

    .views-field-field-fc-cd-location {
      font-size: 16px;
      color: $grey2;
    }
  }
}

.view-sgta-dash-course-details {
  .item-list {
    > ul {
      margin-left: 0;
      ul, ol {
        margin-left: 40px;
        li {
          list-style-type: disc;
        }
      }
    }
  }
}

.course-dashboard {
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #222;
  margin-bottom: 25px;
}

// Courses attended

#block-sgta-sgta-db-course-feedback {
  padding: 40px 0;

  h2.block-title {
    font-size: 28px;
    margin-bottom: 35px;
    text-transform: uppercase;
  }

  .form-type-radios {

    .form-radios {

      .form-item {
        display: inline-block;
        margin-right: 15px;
      }

      .form-radio {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }
  }

  label {
    font-size: 16px;
    color: $grey2;
    margin-bottom: -20px;
  }

  p {
    font-size: 16px;
    color: $grey2;
  }

  .form-required {
    display: none;
  }
}

//body.user-course-details {
//
//  #zone-content-wrapper #zone-content {
//    align-items: start;
//  }
//
//  //.zone-wrapper.zone-suffix-two-wrapper {
//  //  padding: 35px 0 30px;
//  //}
//
//
//}

.flex-control-nav {
  z-index: 99 !important;
}

.listing-availability {
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  color: $orange;
  text-transform: uppercase;
  background: rgba(255, 88, 43, 0.15);
  padding: 2px 5px;

  &.listing-availability--booked {
    background: rgba(127, 127, 127, 0.15);
    color: $grey9;
  }
}


.zone-prefix-one {
  .block-views {
    border-top: 1px solid $grey7;
  }
}
.individual-course {
  .block-views-sgta-courses-courses-header {
    border-top: 1px solid $grey7;
  }
}

.page-user-courses .button .prevent-download {
  background-color: transparent !important;
  color: #344651 !important;
  border: 2px solid #344651 !important;

  &:hover {
    background-color: transparent;
    color: #344651 !important;
    border: 2px solid #344651;
  }
}

.region-prefix-one-top-inner {
  border-botom: 1px solid
}

.individual-course,
.page-user-courses {
.region-suffix-one-first-inner {
    padding-left: 40px;
    border-left: 2px solid $offwhite;
  }
}

.individual-course {
  .zone-content-wrapper {
    padding-top: 0;
  }

  .zone-suffix-two-wrapper,
  .zone-suffix-four-wrapper {
    background: $offwhite;
  }
}


.page-user-courses {
  .region-prefix-one-top .block-sgta-dash-course-details-header {
    background: $white;
    border-bottom: 2px solid #f5f5f5;
    padding-bottom: 55px;
  }

  .zone-suffix-three-wrapper {
    background: $white;
  }
}

//.tech-academy-landing {
//  .zone-suffix-two-wrapper,
//  .zone-suffix-four-wrapper {
//    background: $offwhite;
//  }
//}

//.context-technical-academy {
//  .zone-suffix-two-wrapper,
//  .zone-suffix-four-wrapper {
//    background: $offwhite;
//  }
//}

