// Register Forms

body.not-logged-in.page-user {
  label {
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 8px;
    color: $black;
  }

  input {
    font-family: 'Roboto';
    font-size: 13px;
    color: $grey6;
    background: $offwhite;
    border: 2px solid $grey5;
    padding: 0 10px;
    width: 100%;
    max-width: 430px;
    height: 35px;
    display: inline-block;
  }

  .description {
    margin-left: 0;
    font-size: 13px;
  }

  .form-submit {
    font-family: Roboto !important;
    font-size: 16px;
    font-weight: 400;
    border: 2px solid #ff582b;
    letter-spacing: 1px;
    transition-duration: .2s;
    border-radius: 4px;
    background-color: #ff582b;
    color: $white !important;
    padding: 0 20px;
    text-transform: uppercase;
    text-shadow: none;
    width: auto;
    height: 40px;

    &:hover {
      background: #ff805e;
    }
  }
}

#messages .messages.error {
  display: none;
}

input.error,
select.error {
  border: 2px solid $red !important;
}

div.error {
  border: none;
  background: none transparent;
  padding: 0;
  margin: -20px 0 20px;
  font-size: 13px;
  font-weight: 300;
  color: $red;
}

// Style checkbox
#sgta-forms-register-user-form,
#sgta-routing-profile {
  .form-type-checkbox {
    position: relative;
    padding: 3px 0;

    input[type="checkbox"] {
      position: relative;
      z-index: 10;
      opacity: 0;
      vertical-align: middle;

      &, & + label:before,
      &:checked + label:before {
        width: 26px;
        height: 26px;
      }

      &, & + label {
        cursor: pointer;
      }

      & + label {
        margin-left: 10px;
      }

      & + label:before,
      &:checked + label:before {
        border-radius: 3px;
        border: 1px solid $grey4;
        box-shadow: inset 0 0 0 5px $white;
      }

      &:checked + label:before {
        background-color: $orange;
      }

      & + label:before,
      &:checked + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
      }
    }

    input[type="radio"] {
      & + label:before,
      &:checked + label:before {
        border-radius: 100%;
      }
    }
  }
}

.block-sgta-sgta-academy-user-register {
  position: relative;
  padding-right: 50px;
  font-size: 13px;
  color: $grey6;

  .form-type-checkbox {
    font-weight: 300;
    color: $black;

    .description {
      margin-left: 0;
      font-size: 13px;
    }
  }

  #sgta-forms-register-user-form a {
    color: $black !important;
    font-weight: 300;
    text-transform: uppercase;
  }

  .fieldset-legend {
    display: inline-block;
    font-family: 'Ubuntu';
    font-size: 28px;
    font-weight: 400;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .fieldset-wrapper {
    .form-item {
      margin: 0 0 25px;

      label {
        font-family: 'Roboto';
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 8px;
        color: $black;
      }

      input, select {
        font-family: 'Roboto';
        font-size: 13px;
        color: $grey6;
        background: $offwhite;
        border: 2px solid $grey5;
        padding: 0 10px;
        width: 100%;
        max-width: 430px;
        height: 35px;
      }
    }

    .form-type-item {
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
      line-height: 1.63;
      color: $grey2;
    }
  }
}

.block-sgta-academy-register-block,
.block-sgta-academy-login-block {
  padding: 0;
  margin-top: 50px;

  .content {
    span {
      font-size: 16px;
      font-weight: 400;
      color: $grey2;
    }

    .button a {
      display: inline-block;
    }
  }
}

.block-sgta-academy-register-block {
  .button a {
    font-size: 16px;
    border: 2px solid #ff582b;
    text-shadow: none;
    letter-spacing: 1px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 19px;
    transition-duration: .2s;
    border-radius: 4px;
    background-color: #ff582b;
    color: #fff !important;
    padding: 15px 20px;
    text-transform: uppercase;
    margin: 10px 0;

    &:hover {
      background: #ff805e;
      text-decoration: none;
    }
  }
}

// Login form
#user-login {
  .block-title {
    font-family: 'Ubuntu';
    font-size: 28px;
    font-weight: 400;
    letter-spacing: normal;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  a {
    font-size: 16px;
    font-weight: 300;
    margin: 10px 0 5px;
    display: inline-block;
  }
}


// Cancel application
.page-users-courses-cancel {
  #sgta-routing-profile {
    justify-content: flex-start;
  }
}