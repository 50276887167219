//Header Title View
.header-title {
  padding: 40px 0px;
  .cdata-header-image {
    display: none;
  }
  .date {
    font-weight: 500;
    margin-bottom: 20px;
    color: $grey;
  }
  .title-wrapper {
    margin-bottom: 20px;
  }
  .page-title-wrapper {
    float: left;
  }
  .page-subtitle {
    border-left: 1px solid #5a5b5c;
  }
  .sharewrap {
    float: left;
    overflow: display;
    height: 36px;
    width: 36px;
    margin-left: 10px;
    padding-top: 4px;
    position: relative;
    .share {
      font-size: 0px;
      color: transparent;
      cursor: pointer;
      height: 36px;
      width: 36px;
      display: block;
      border-radius: 50%;
      background: url('../images/icon-share.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-color: $orange;
      @include trans;
      &:hover {
        background-color: $black;
      }
    }
    .sharepop-wrapper {
      display: none;
      position: absolute;
      top: 45px;
      right: 0px;
      padding-top: 12px;
      background-image: url('../images/flyout-mobile.png');
      background-repeat: no-repeat;
      background-position: 100px 0px;
      .sharepop {
        background-color: $white;
        border: 4px solid $orange;
        width: 130px;
        height: 105px;
        .share-wrapper {
          padding: 5px 10px;
          clear: both;
          &.facebook {
            padding-bottom: 10px;
          }
        }
      }
    }
    &:hover .sharepop-wrapper {
      display: block;
    }
  }
}

//Pager Resets
//Views Pager Styles
ul.pager {
  background: none !important;
  background-color: none;
  border: 0 none;
  border-color: transparent;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  li {
    background: none !important;
    background-color: $white !important;
    color: $orange;
    font-family: "Ubuntu";
    font-weight: 600;
    font-style: normal;
    border-right: 0 none;
    border-color: transparent;
    &.pager-current {
      background: $orange !important;
      background-color: $orange !important;
      color: $white;
    }
    &.first, &.first a, &.first a:hover {
      border-radius: 0 0 0 0;
      -moz-border-radius: 0 0 0 0;
      -webkit-border-radius: 0 0 0 0;
    }
    &.pager-next, &.pager-last, &pager-next.last {
      position: relative;
      right: 0;
      border-left: 0 none;
    }
    a {
      background: none !important;
      background-color: $white !important;
      color: $orange;
    }
  }
}

.pager li.pager-first a, .pager li.pager-previous a, .pager li.pager-next a, .pager li.pager-last a {
  width: auto;
  padding: 4px;
}

//Flexslider Resets
.flexslider {
  margin: 0 0 0 0 !important;
  background-color: $ftrblack !important;
  border: 0 none !important;
  position: relative;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  border-radius: 0 !important;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  -o-box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  zoom: 1;
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  padding-bottom: 10px !important;
  bottom: 0 !important;
  z-index: 998;
  text-align: center;
}

.flex-control-paging li {
  border: 1px solid $white;
  padding: 3px;
  margin: 0 3px;
  @include radius100;
  background: transparent;
}

.flex-control-paging li a {
  width: 16px !important;
  height: 16px !important;
  background: transparent !important;
  background: rgba(255, 255, 255, 0) !important;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.0) !important;
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.0) !important;
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.0) !important;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.0) !important;
  @include trans;
}

.flex-control-paging li a.flex-active {
  background: #fff !important;
  background: rgba(255, 255, 255, 1) !important;
}

//Home slider
body.front {
  .flexslider {
    .slide-wrapper {
      text-align: center;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      .slide-wrapper-inner {
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 75%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0, 0, 0, 0.85)), color-stop(75%, rgba(0, 0, 0, 0))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 75%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 75%); /* Opera 11.10+ */
        background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 75%); /* IE10+ */
        background: linear-gradient(to right, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 75%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1); /* IE6-9 */
        color: $white !important;
      }
      a {
        display: block;
        text-decoration: none;
        color: $white !important;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .caption-wrapper.grid-24 {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      padding-top: 60px;
      padding-bottom: 40px;
      text-align: left;
      float: none;
      .caption-inner-top {
        width: 80%;
        margin-left: 10px;
        h2.slide-title {
          font-size: 28px !important;
          text-transform: uppercase;
          letter-spacing: 0.02em;
          margin-bottom: 30px;
        }
        .views-field-field-fc-slides-subtitle {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }
}

body.front.ie-8,
body.front.ie-7 {
  .flexslider {
    .slide-wrapper {
      .slide-wrapper-inner {
        background: url("../images/grad-740.png");
        background-position: center top;
        background-repeat: repeat-y;
      }
    }
  }
}

//Header Image Grid
.header-image.himage-grid {
  img {
    width: 100%;
    height: auto;
  }
}

//Header Image Full
.header-image.himage-full {
  max-height: 300px;
  overflow: hidden;
}

//Front Tagline View
.tag1,
.tag2 {
  font-family: 'Ubuntu';
  font-size: 24px;
}

.tag1 {
  color: $navy;
  font-weight: 700;
}

.tag2 {
  color: $grey;
  font-weight: 500;
}

//Front Page Tiles
.tiles {
  margin-bottom: 0;
  .tile {
    margin-bottom: 20px;
    .tile-image {
      position: relative;
      img {
        width: 100%;
        max-width: 380px;
        height: auto;
      }
      .orange-box-link {
        background-image: url("../images/right.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $orange;
        @include trans;
        display: block;
        height: 65px;
        width: 60px;
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
    }
    .tile-content {
      background-color: $offwhite;
      padding: 20px;
      @include boxsize;
      max-width: 380px;
      h2.tile-title {
        font-size: 21px;
        margin-bottom: 10px;
        color: $black !important;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        @include trans;
      }
      .tile-text {
        padding-left: 20px;
        border-left: 1px solid $orange;
        color: $blue;
        font-weight: 500;
        p {
          margin-bottom: 0;
        }
      }
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
        h2.tile-title {
          color: $orange !important;
        }
        .orange-box-link {
          background-color: $black;
        }
      }
    }
  }
  .views-row-1,
  .views-row-4,
  .views-row-7,
  .views-row-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .views-row-3,
  .views-row-6,
  .views-row-9,
  .views-row-12 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

//Brand Tiles
.tiles.brands {
  .tile {
    margin-bottom: 20px;
    .tile-image {
      border: 2px solid $offwhite;
      max-width: 376px;
    }
    .tile-content {
      .tile-tel,
      .tile-email {
        padding-left: 30px;
        margin-bottom: 8px;
        font-weight: 500;
        color: $grey;
        background-position: left center;
        background-repeat: no-repeat;
        letter-spacing: 0.02em;
        a {
          color: $grey !important;
          @include trans;
          &:hover {
            color: $orange !important;
            text-decoration: none;
          }
        }
      }
      .tile-tel {
        background-image: url('../images/icon-tel-20-blue.png');
      }
      .tile-email {
        background-image: url('../images/icon-mail-20-blue.png');
      }
      .tile-link {
        padding-top: 10px;
        a {
          @include trans;
          &:hover {
            color: $grey !important;
          }
        }
      }
    }
  }
  .views-row-1,
  .views-row-5 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .views-row-3,
  .views-row-6,
  .views-row-7 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .views-row-4,
  .views-row-8 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

//Training Academy Tiles
.tiles.academy {
  margin-top: 40px;
  .tile {
    margin-bottom: 20px;
  }

}

//Twitter
.twitter {
  .twt-icon {
    float: none;
    width: 100%;
    text-align: center;
    img {
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
  .twitter-content {
    float: none;
    width: 100%;
    .twitter-text {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 300;
      a {
        font-weight: 300;
      }
    }
    .twitter-name {
      float: left;
      font-size: 12px;
      color: $grey;
      a {
        color: $grey !important;
        @include trans;
        font-weight: 300;
        &:hover {
          color: $orange !important;
          text-decoration: none;
        }
      }
    }
    .twitter-date {
      float: left;
      font-size: 12px;
      color: $grey;
      &:before {
        content: "•";
        margin-left: 3px;
        padding-right: 3px;
      }
    }
  }
}

//Courses Calendar
.block-views-course-calendar-course-block {
  display: none;
}

//Courses Accordion
.courses.listing {
  margin-bottom: 40px;
  .content-left {
    width: 100%;
    float: none;
    margin-right: 0;
  }
  .content-right {
    width: 100%;
    float: none;
    margin-top: 20px;
    padding-top: 20px;
    padding-left: 0;
    border-left: 0 none;
    border-top: 1px solid $lgrey;
  }
}

//Accordion Resets
.ui-widget {
  font-family: 'Roboto' !important;
  font-weight: 300;
  font-size: 14px;
}

.ui-widget-content {
  border: 1px solid $offwhite;
  h3, h4 {
    font-family: 'Ubuntu' !important;
  }
  h3 {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: $grey !important;
  }
  h4 {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: $blue !important;
  }
  h4.dates-heading {
    font-family: 'Roboto' !important;
    font-weight: 500;
    font-size: 12px;
    color: $grey !important;
    margin-bottom: 10px;
  }
  ul {
    margin-left: 20px;
    margin-bottom: 20px;
    li {
      list-style: disc;
    }
  }
  a.download-btn {
    display: inline-block;
    padding: 8px 20px;
    background-color: $orange;
    color: $white !important;
    font-family: 'Ubuntu' !important;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 12px;
    @include radius4;
    @include trans;
    &:hover {
      background-color: $offwhite;
      color: $orange !important;
      text-decoration: none;
    }
  }
  .accred {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    .label {
      text-transform: uppercase;
      letter-spacing: 0.02em;
      color: $grey;
      font-size: 12px;
    }
  }
  .upcoming-dates {
    ul {
      margin-left: 0;
      li {
        margin-left: 0;
        list-style: none;
        font-weight: 500;
        line-height: 1.2em;
      }
    }
  }
}

.ui-accordion .ui-accordion-header {
  font-family: 'Ubuntu' !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 8px 40px 8px 20px;
  font-size: 16px;
  font-weight: 300;
  @include smooth;
  a:hover {
    text-decoration: none;
  }
}

.ui-accordion .ui-accordion-content {
  padding: 20px;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 0 none;
  background: $lblue;
  a {
    color: $blue !important;
    @include trans;
    &:hover {
      color: $orange !important;
    }
  }
  &:hover {
    a {
      color: $orange !important;
    }
  }
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: 0 none;
  background: $blue;
  a {
    color: $white !important;
    &:hover {
      color: $white !important;
    }
  }
  &:hover {
    a {
      color: $white !important;
    }
  }
}

.ui-icon {
  width: 24px;
  height: 24px;
}

.ui-state-default .ui-icon {
  background-image: url('../images/arw-more-24.png');
  background-position: center center;
}

.ui-state-active .ui-icon {
  background-image: url('../images/arw-exp-24.png');
  background-position: center center;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  top: 14px;
  right: 16px;
  left: auto;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 0;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 0;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 0;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 0;
}

//Testimonials
.testimonials {
  .testimonial-item {
    margin-bottom: 20px;
    .quote {
      display: table;
      margin-bottom: 10px;
      padding-bottom: 10px;
      background-image: url('../images/arw-quote.png');
      background-repeat: no-repeat;
      background-position: 40px bottom;
      .quote-inner {
        display: table-cell;
        vertical-align: middle;
        padding: 20px;
        background-color: $offwhite;
        font-size: 15px;
        font-style: italic;
        font-weight: 300;
        @include boxsize;
        @include radius4;
      }
    }
    .qt-info {
      font-family: 'Ubuntu' !important;
      color: $grey;
      margin-left: 10px;
      .qt-name {
        color: $black;
        font-weight: 700;
        font-style: normal;
      }
    }
  }
  .views-row-1,
  .views-row-4,
  .views-row-7 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .views-row-3,
  .views-row-6,
  .views-row-9 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

//Projects & Guides
.projects {
  .project-item {
    margin-bottom: 20px;
    position: relative;
    .project-image {
      img {
        width: 100%;
        height: auto;
      }
    }
    .project-grad {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65))); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* IE10+ */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
      h3 {
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        display: block;
        height: 100%;
        a {
          display: block;
          height: 100%;
          box-sizing: border-box;
          padding: 20px;
          color: $white !important;
          @include trans;
          &:hover {
            color: $orange !important;
            text-decoration: none;
          }
        }
      }
    }
    &:after {
      content: "";
      font-size: 0px;
      background: url(../images/download.png) 50% 50% no-repeat $orange;
      background-size: 26px 32px;
      height: 65px;
      width: 60px;
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      @include trans;
    }
    &:hover:after {
      background-color: $black;
    }
  }
  .views-row:nth-child(3n+3) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .views-row-first {
    margin-left: 10px;
    margin-right: 10px;
  }
  .views-row:nth-child(3n+1) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

//Guides
.guides {
  .guide {
    margin-bottom: 20px;

    background-color: $offwhite;
    @include boxsize;
    .guide-image {
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .orange-box-link {
        background: url(../images/download.png) 50% 50% no-repeat $orange;
        background-size: 26px 32px;
        height: 65px;
        width: 60px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        @include trans;
        &:hover {
          background-color: $black;

        }
        a {
          display: block;
          height: 100%;
          font-size: 0px;

        }
      }
    }
    .content-container {
      padding: 20px 20px 20px 20px;
      h3 {
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        @include trans;

      }
      .desc {
        @include indent;
      }
      .wrapper-link {
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        height: 100%;
        width: 100%;
      }
    }
    &:after {
      content: none;
    }
    &:hover {
      .orange-box-link {
        background-color: $black;
      }
      h3 {
        color: $orange !important;
      }
    }
  }
}

//Events
.events {
  .event-item {
    background-color: $offwhite;
    margin-bottom: 20px;
    padding: 20px;
    @include boxsize;
    h3 {
      font-size: 21px;
      margin-bottom: 10px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      a {
        @include trans;
        &:hover {
          color: $black !important;
          text-decoration: none;
        }
      }
    }
    .event-date {
      font-weight: 500;
      font-size: 13px;
    }
    .location {
      font-weight: 500;
      font-size: 13px;
    }
    .desc {
      margin-top: 20px;
      @include indent;
    }
    .read-more {
      font-family: 'Ubuntu' !important;
      font-size: 12px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      a {
        @include trans;
        &:hover {
          color: $grey !important;
        }
      }
    }
  }
}

//Event Page title
.event-date,
.event-location {
  font-weight: 500;
  font-size: 14px;
  color: $grey;
}

.event-location {
  font-style: italic;
}

//News
.news {
  .news-item {
    background-color: $offwhite;
    margin-bottom: 20px;
    .news-img {
      width: 100%;
      float: none;
      img {
        width: 100%;
        height: auto;
      }
    }
    .news-item-content {
      float: none;
      padding: 5%;
      width: 100%;
      @include boxsize;
      h2 {
        font-size: 18px;
        margin-bottom: 5px;
        a {
          @include trans;
          &:hover {
            color: $black !important;
            text-decoration: none;
          }
        }
      }
      .date {
        color: $grey;
        font-weight: 500;
        font-size: 11px;
        margin-bottom: 20px;
      }
      .desc {
        @include indent;
        margin-bottom: 0;
        p {
          margin-bottom: 0;
        }
      }
      .read-more {
        font-family: 'Ubuntu' !important;
        font-size: 12px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        a {
          @include trans;
          &:hover {
            color: $grey !important;
            text-decoration: none;
          }
        }
      }
    }
  }
  .views-row-odd {
    margin-left: 10px;
    margin-right: 10px;
  }
  .views-row-even {
    margin-left: 10px;
    margin-right: 10px;
  }
}

// Technical academy module (sgta)
// dashboard
.sgta.dashboard-wrapper {
  
  table {
    &, & * {
      border: none;
      font-weight: normal;
      color: $mblue;
    }

    th {
      color: $navy;
      font-size: 16px;
      padding: 20px;
      background-color: $lblue;
    }

    tr {
      &.even {
        td {
          background-color: $offwhite;
        }
      }
    }
    td {
      padding: 10px 20px;
    }
  }
}

.page-technical-academy-manage {
  .sgta{
    .view-footer {
      z-index: 14 !important;
    }
  }
}

.horizontal-tabs {
  table {
    &, & * {
      border: none;
      font-weight: normal;
      color: $mblue;
    }

    th {
      padding: 20px;
      background-color: $lblue;
    }

    tr {
      &.even {
        td {
          background-color: $white;
        }
        border-top: 1px solid $lblue;
      }
    }
    td {
      padding: 10px 20px;
    }
  }
}

.sgta-view {
  margin: 30px 0 30px 0;
  &.sgta-cards {
    .sgta-card {
      width: 100%;
      margin: 15px 0;
      position: relative;
      .course-inner-image {
        position: relative;
        .course-inner-shadow {
          min-height: 250px;
          .course-link a,
          .course-link a:hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            color: transparent;
            font-size: 0;
            text-decoration: none;
            background-image: url(about:_blank);
            box-sizing: border-box;
          }

          .card-title {
            @include trans;
            font-size: 21px;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
            padding: 15px 20px;
          }

          .card-date {
            @include trans;
            padding: 25px 50px 20px 60px;
            color: $offwhite;
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;
            left: 0;
            box-sizing: border-box;
            background: $ftrblack url(../images/icon-calendar-white-24.png) no-repeat 20px center;
            .card-arrow {
              @include trans;
              box-sizing: border-box;
              height: 100%;
              width: 50px;
              position: absolute;
              right: 0;
              top: 0;
              background: $orange url(../images/right.png) no-repeat center center;
            }
          }
          &:hover {
            .card-title {
              color: $orange;
            }
            .card-date {
              color: $orange;
            }
            .card-date {
              background: $ftrblack url(../images/icon-calendar-orange-24.png) no-repeat 20px center;
              .card-arrow {
                background: $black url(../images/right.png) no-repeat center center;
              }
            }
          }
        }
      }

      &.quicklinks {
        .course-inner-shadow {
          padding-top: 40px;
        }
      }
      .card-quicklink {
        @include trans;
        position: absolute;
        top: 0px;
        left: 0px;
        height: auto;
        width: 100%;
        display: block;
        box-sizing: border-box;
        z-index: 20 !important;
        .quicklink-item {
          @include trans;
          font-weight: 300;
          display: block;
          padding: 10px 20px 10px 32px;
          background-color: rgb(0, 0, 0);
          background-color: rgba(0, 0, 0, 0.8);
          color: $white !important;
          background-repeat: no-repeat;
          background-position: 10px center;
          &.confirm {
            background-image: url(../images/icon-bell-16-orange.png);
          }
          &.confirmed {
            background-image: url(../images/icon-checkmark-16-orange.png);
          }
          &.feedback {
            background-image: url(../images/icon-star-16-orange.png);
          }
          &.rated {
            background-image: url(../images/icon-checkmark-16-orange.png);
          }
        }
        &:hover {
          .quicklink-item.confirm {
            background-color: rgb(255, 88, 43);
            background-color: rgba(255, 88, 43, 0.9);
            color: $black !important;
            text-decoration: none;
            background-image: url(../images/icon-bell-16-white.png);
          }
          .quicklink-item.feedback {
            background-color: rgb(255, 88, 43);
            background-color: rgba(255, 88, 43, 0.9);
            color: $black !important;
            text-decoration: none;
            background-image: url(../images/icon-star-16-white.png);
          }
        }
      }
    }
  }
  &.sgta-cards-small {
    .sgta-card {
      width: 100%;
      margin: 15px 0;
      position: relative;
      .course-inner-shadow {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-bottom: 70px;
        min-height: initial !important;
      }
    }
  }
  // Exposed Views
  .views-exposed-widgets {
    margin-bottom: 40px;
    input[type="text"],
    select {
      background-color: $offwhite;
      border: 2px solid $lgrey;
      height: 36px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
    }
    input[type="submit"] {
      background: $orange !important;
      background-colour: $orange !important;
      text-shadow: none;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      color: $white;
      letter-spacing: 0.05em;
      font-family: 'Ubuntu' !important;
      text-transform: uppercase;
      border: 0 none;
      padding: 10px 24px;
      @include trans;
      &:hover {
        background: $offwhite !important;
        background-colour: $offwhite !important;
        color: $orange;
      }
    }
  }
}

.page-user {
  .region-suffix-2-inner,
  .region-suffix-3-inner {
    .block-views {
      //h2.block-title{
      //	font-size: 21px;
      //	letter-spacing: 1px;
      //	text-transform: uppercase;
      //	padding: 5px 0px 5px 40px;
      //}
    }
  }

  //.region-suffix-2-inner{
  //	.block-views{
  //		padding-bottom: 40px;
  //		h2.block-title{
  //			background: url(../images/icon-upcoming-courses.png) no-repeat left center;
  //		}
  //	}
  //}
  //.region-suffix-3-inner{
  //	.block-views{
  //		padding-bottom: 40px;
  //		h2.block-title{
  //			background: url(../images/icon-past-courses.png) no-repeat left center;
  //		}
  //	}
  //}
}

//sgta views
#block-views-sgta-course-details-location,
#block-views-sgta-course-details-duration,
#block-views-sgta-course-details-hours,
#block-views-sgta-course-details-cost,
#block-views-sgta-course-details-dates {
  //padding-top: 20px;
  //padding-left: 15px;
  //width: 82%;
  //min-width: 200px;
  //h2.block-title {
  //  display: block;
  //  color: $navy;
  //  text-transform: uppercase;
  //  padding-bottom: 5px;
  //  border-bottom: 2px solid $lgrey;
  //}
  .block-inner > .content {
    padding: 10px 15px;
    .field-content {
      color: $grey;
      font-size: 17px;
      font-weight: 400;
    }
  }
}

.block-views-sgta-course-details-dates {
  .block-inner > .content {
    .views-row {
      margin-bottom: 20px;

      .date-date,
      .date-location {
        color: $grey;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

#block-views-sgta-course-details-download {
  padding-top: 20px;
  padding-left: 15px;
  width: 80%;
  min-width: 200px;
  .download-link {
    text-align: center;
    a .download-name {
      color: $grey !important;
    }
    & a:hover {
      .download-name {
        color: $orange !important;
      }
    }
  }
}

#block-views-sgta-course-details-topics {
  padding-top: 15px;
  padding-bottom: 20px;

  .view-content {
    padding-left: 1.5em;

    li {
      list-style: disc;
      font-size: 16px;
      color: $grey2;
      line-height: 1.5em;
    }
  }
}

#block-views-sgta-applications-block-1 {
  padding-bottom: 55px;
  h2.block-title {
    margin: 1em 0;
  }
}

#block-views-sgta-applications-date-votes {
  h2.block-title {
    margin: 1em 0;
  }
}

#block-views-sgta-attendance-title {
  .title-text,
  .title-date {
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 20px;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px 20px;
  }

  .title-text {
    border-top: 1px solid $navy;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

}

// Showing Availability
.availability {
  font-weight: 400;
  text-transform: uppercase;

  span {
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    padding: 2px 5px;
    margin-top: 3px;
  }

  .full {
    background: rgba(127, 127, 127, 0.15);
    color: $grey9;
  }

  .available {
    background: rgba(255, 88, 43, 0.15);
    color: $orange;
  }
}

.course-availability {
  display: inline-block;
  margin-bottom: 20px;

  .availability {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    padding: 2px 5px;
    background: rgba(127, 127, 127, 0.15);
    color: $grey9;
  }
}

#block-views-sgta-courses-calendar-calendar {
  .availability {
    font-size: 11px;
  }
}

.listing-availability {
  color: $red;
}

//
.view-display-id-course_listing_page_header {
  padding: 22px 0 32px;
  > .view-content .sgta-card.views-row-1 {
    max-width: 650px;
    margin: 0 auto;
    text-align: center;

    .card-title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Ubuntu';
      font-size: 28px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 20px;

      svg {
        margin-bottom: 13px;
      }
    }

    .views-field-field-subtitle p {
      font-family: 'Roboto';
      font-size: 16px;
      line-height: 26px;
      color: $grey2;
    }
  }
}

.view-sgta-courses-listing {
  flex-wrap: wrap;
  margin: 0;

  .view-sgta-courses-listing--inner {
    margin: 0 10px;

    > .view-content {
      display: flex;
      justify-content: center;
      flex: 1 1 100%;

      .views-row {
        background: transparent;
        width: 100%;
        max-width: none;
        margin: 0 0 20px;
        text-align: center;
      }

      .card-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Ubuntu';
        font-size: 28px;
        font-weight: 500;
        text-transform: uppercase;

        svg {
          margin: 0 15px 0 0;
        }
      }

      .views-field-field-subtitle {
        max-width: 650px;
        margin: 0 auto;

        p {
          font-size: 16px;
          line-height: 26px;
          color: $grey2;
          margin-bottom: 20px;
        }
      }
    }
  }

  .view-footer {
    flex: 1;

    .view-sgta-courses-listing {
      flex-wrap: wrap;
      margin: 0;
      padding: 0;

      > .view-content {
        flex: 0 1 100%;
        margin-bottom: 10px;

        .views-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
          max-width: none;
          margin: 0;

          img {
            width: 100%;
          }
        }
      }

      .course-image {
        width: 100%;
        height: 120px;
      }

      //
      .course-text {
        width: 100%;
        padding: 20px 10px;

        .card-title-wrapper {
          justify-content: flex-start;
          margin-bottom: 10px;

          a {
            font-size: 21px;
          }
        }

        .availability-label {
          display: inline-block;
          margin-bottom: 10px;
        }

        .card-subheading {
          font-size: 16px;
          line-height: 26px;
          color: $grey2;
          margin-bottom: 20px;
        }

        .card-date {
          margin-bottom: 20px;
          justify-content: flex-start;

          .date-display-single,
          .date-display-range {
            display: inline-block;
            margin-left: 7px;
          }
        }
      }

      .attachment {
        display: flex;
        flex-wrap: wrap;
        flex: 0 1 100%;

        .view-sgta-courses-listing {
          flex: 1;
          margin: 0;
          padding: 0;
        }

        .view-content {
          flex: 1;
          justify-content: space-between;
        }

        .views-row {
          flex: 0 1 49%;
          margin: 0 0 10px;
        }

        .course-text {
          box-sizing: border-box;
          padding: 10px 12px;

          .card-title-wrapper {
            justify-content: flex-start;
            margin-bottom: 0;

            a {
              font-size: 16px;
              color: $grey2 !important;
              text-align: left;
            }
          }

          .card-date {
            flex: none;
            display: block;
            margin-top: 20px;
            //height: 100%;
          }
        }

        .course-text-alt {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}

// Academy Listings Calendar View Switch Button
//.button-switch-calendar {
//  display: none;
//}

.view .date-nav-wrapper .date-nav {
  height: 95px;
  padding-top: 20px;

  .date-heading {
    height: 20px;
  }

  .pager {
    position: absolute;
    width: 100%;
    top: auto;
    bottom: 10px;
    right: 0;
    background-color: transparent;
    height: 41px;
    transform: none
  }
}

.view .date-nav-wrapper .date-nav {
  display: flex;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  background-color: $white;
  padding-bottom: 0;

  .date-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 56px;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
    color: $orange;
    text-align: center;
    margin: 0 20px;
    text-transform: uppercase;
  }

  //.pager {
  //  position: absolute;
  //  width: 100%;
  //  top: 50%;
  //  right: 0;
  //  background-color: transparent;
  //  height: 41px;
  //  transform: translateY(-50%);
  //}

  .date-toggle {
    position: absolute;
    padding: 0;

    a {
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      border-radius: 5px;
      border: 2px solid $orange;
      background: $orange !important;
      color: $white !important;
      padding: 9px 20px;
      transition: all 0.3s;

      &:hover {
        background: #ff805e !important;
        color: $white !important;
        transition: all 0.3s;
      }
    }
  }

  .ajax-progress {
    display: none;
  }
}

.view-sgta-courses-calendar {
  .calendar-calendar {
    margin-bottom: 40px;

    th.days {
      font-size: 11px;
      border: 0;
      color: $black;
      padding: 4px 0;
    }

    td {
      border: 1px solid $grey5;
    }

    .month-view .full td.single-day {
      height: 65px !important;

      div.monthview {
        border-bottom: 0;
        margin: 0;
      }
    }

    .month-view .full td.single-day {

      .views-field-title a {
        font-size: 11px;
        font-weight: 500;
        background: none;
      }

      &.future .views-field-title a {
        color: $black !important;

        &:hover {
          color: $orange !important;
        }
      }

      &.past .views-field-title a {
        color: $grey10 !important;
      }
    }

    .month-view .full td .inner div.calendar.monthview .contents {
      //display: flex;
      //flex-wrap: wrap;
      //justify-content: space-between;
      height: 65px;

      //> div {
      //  flex: 1 0 100%;
      //}
      //
      //> div:last-of-type {
      //  display: flex;
      //  align-items: flex-end;
      //}

      .availability {
        position: absolute;
        left: 2px;
        bottom: 2px;

        span {
          display: inline-block;
          font-size: 11px;
          padding: 4px 5px 2px;
        }

        .full {
          background: rgba(127, 127, 127, 0.15);
          color: $grey9;
        }

        .available {
          background: rgba(255, 88, 43, 0.15);
          color: $orange;
        }
      }
    }
  }
}

.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview {
  margin-bottom: 5px;
  padding-bottom: 2px;
  background: transparent;
  line-height: 1.2em;
  border-bottom: 1px solid #d1d1d1;
  @include radius-none;
}

.calendar-calendar .month-view .full td .inner .month.day,
.calendar-calendar .month-view .full td .inner .month.day a {
  font-size: 11px;
  font-weight: 500;
  color: $black;
}

.block-sgta-courses-listing-listing,
.block-sgta-courses-calendar-calendar {
  .view-filters {
    margin-top: 50px;
    margin-bottom: 20px;
    position: relative;
    height: 80px;

    .js-listing-switch {
      position: absolute;
      top: 0;
      right: 0;

      .form-item:first-of-type a {
        margin-right: 10px;
      }
    }
  }

  .view-content {
    .views-row {
      margin-top: 0;

      img {
        width: 100%;
      }
    }
  }
}

.block-sgta-courses-listing-listing {
  .view-sgta-courses-listing {
    flex-direction: column;

    .view-filters {
      margin-top: 50px;
      margin-bottom: 25px;
      position: relative;
      height: 150px;

      .js-listing-switch {
        position: absolute;
        //top: 0;
        //right: 0;
        top: 70px;
        right: auto;
        left: -10px;

        .form-item:first-of-type a {
          margin-right: 10px;
        }
      }

      .form-type-select {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          bottom: 19px;
          right: 15px;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 11px solid $white;
          z-index: 10;
        }
      }

      select {
        font-size: 16px;
        background-color: $orange;
        color: white;
        padding: 15px 60px 15px 20px;
        height: auto;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        font-family: Roboto, Arial;
        border: none 0 transparent;
        background-image: none;
        position: relative;
      }
    }

    .views-exposed-widgets {
      margin-bottom: 0;
    }
  }
}

// Heading title

.header-title-alt {
  display: flex;
  position: relative;
  height: auto;
  overflow: hidden;

  .view-content {
    flex: 1;
  }

  .views-row {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;

    .header-title-alt__img {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .header-title-alt__text {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 20px 10px;
    }

    .header-title-alt__text-inner {
      display: flex;
      flex-wrap: wrap;
      flex: 0 1 290px;

      svg {
        flex: 0 1 64px;
        margin-right: 20px;
      }

      h1 {
        flex: 1;
        color: $grey2;
        text-transform: uppercase;
      }

      p {
        flex: 1 0 100%;
        font-size: 18px;
        font-weight: 400;
        color: $black;
        line-height: 24px;
      }
    }
  }
}

.view-sgta-courses-listing {
  img {
    width: 100%;
  }
}

.view-sgta-courses-listing>.view-content .card-title-wrapper a {
  color: #222!important;
}

.block .academy-landing-listing-view {
  margin: 0 10px;
  padding: 0 0 40px;

  .view-footer {
    flex: 0 1 auto;
    margin-top: 20px;

    .button--orange {
      text-align: center;
    }
  }
}

// Individual courses
.course-date {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: $grey2;
  font-weight: 400;
  margin-bottom: 20px;

  svg {
    margin-top: -3px;
    margin-right: 5px;
  }
}

.individual-course {
  .view-display-id-courses_header {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $offwhite;
    margin-bottom: 30px;

    .view-content {
      flex: 0 1 1180px;
      margin-bottom: 25px;
    }

    .views-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 30px;
    }
  }

  .block-title {
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 15px 0;
  }

  .course-info {
    margin: 0 0 30px 0;
  }

  .course-cta {
    .form-item,
    .button {
      margin: 0;
    }
  }

  .course-info,
  .course-image {
    flex: 1 0 100%;
  }
}

.course-content.course-image {
  img {
    max-width: 100%;
    border-radius: 0 0 3px 3px;
  }
}

// Course Overview View
.page-user-courses {
  .course-topics-view .item-list {
    > ul {
      margin-left: 40px;

      .views-row {
        display: list-item;
      }
    }
  }
}

// Course author
.author {
  display: flex;
  justify-content: center;
  padding: 40px 0 35px;

  .author__inner {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 300px;
    justify-content: center;
  }

  .author__image {
    margin-right: 0;
    margin-bottom: 30px;

    img {
      border-radius: 50%;
    }
  }

  .author__text {
    flex: 1 0 100%;

    h2 {
      font-size: 28px;
    }

    h2:first-of-type {
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      color: $grey2;
      line-height: 26px;
    }
  }
}


.view-sgta-dash-course-details {

  .region-suffix-one-first-inner {
    padding-left: 40px;
    border-left: 2px solid $offwhite;
    margin-left: 40px;
  }
}


.page-user-courses,
.individual-course {

  .course-header.view-sgta-dash-course-details,
  .course-header.view-display-id-courses_header {

    .view-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .views-row {
      display: flex;
      flex: 0 1 1180px;
      margin: 0 10px;
      padding-top: 0 !important;
    }

    .course-info {
      flex: 1;
    }
    author__image
    .course-image {
      flex: 0 1 380px;

      img {
        max-width: 380px;
      }
    }
  }
}

.view-id-sgta_dash_course_details {

  .view-id-sgta_dash_course_details,
  .view-id-sgta_dash_course_hours,
  .view-id-sgta_dash_course_duration {
    font-size: 16px;
    color: #646464;
  }

  &.view-display-id-location {
    .views-row {
      display: flex;
      flex-wrap: wrap;

      .views-field-field-fc-cd-location {
        margin-bottom: 25px;
      }
    }
  }
}
// Fixes for the management section
.page-technical-academy-manage  {
  .sgta-view {
    flex-direction: column;
  }
  
  #sgta-routing-manage-academy {
    div {
      width: 100%;
    }
  }
}

.block-sgta-courses-listing-listing .view-sgta-courses-listing .view-filters {
  margin-top: 50px;
  margin-bottom: 25px;
  position: relative;
  margin-left: -10px;
}

.block-sgta-courses-listing-listing .view-sgta-courses-listing select {
  font-size: 16px;
  background-color: $orange;
  color: white;
  padding: 15px 60px 15px 20px;
  height: auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-family: Roboto, Arial;
  border: 2px solid transparent;
  background-image: none;
  position: relative;
}
.block-sgta-courses-listing-listing .view-sgta-courses-listing select:hover {
  background-color:#ff7454 !important;
  border: 2px solid #ff582b;
}