//Main Menu and Quicklinks
.block-menu-block-1,
.responsive-layout-mobile .block-menu-block-3{
  position: relative;
  ul.menu {
    position: inherit;
    top: 44px;
    right: 0;
    li {
      float: none;
      text-transform: uppercase;
      margin: 0 5px;
      padding: 0 0 0 0;
      border-bottom: 1px solid $offwhite;
      a {
        display: block;
        padding: 6px 12px;
        margin: 0 0 0 0;
        font-family: 'Ubuntu' !important;
        font-weight: 500;
        font-size: 16px;
        color: $ftrblack !important;
        letter-spacing: 0.05em;
        @include trans;
        &:hover{
          color: $orange !important;
          text-decoration: none;
        }
        &.active, &.active-trail{
          color: $orange !important;
        }
      }
      &.first {
        border-top: 1px solid $offwhite;
      }
    }
    & ul {
      display: none;
    }
  }
}
.responsive-layout-mobile .block-menu-block-3{
	ul.menu{
		li{
			&.first{
				border-top: 0px none transparent;
			}
		}
	}
}

//Footer Menu
.block-menu-block-2 {
	ul.menu {
		margin-top: 0;
		margin-left: 40px;
		margin-bottom: 20px;
		li {
			margin: 0 0 8px 0;
			padding: 0 0 0 0;
			list-style-image: url('../images/icon-disc.png');
			width: 100%;
			float: none;
			a {
				font-size: 13px;
				font-weight: 500;
				letter-spacing: 0.04em;
				color: $white !important;
				@include trans;
				&:hover {
					text-decoration: none !important;
					color: $orange !important;
				}
			}
		}
	}
}


// Technical Academy module (sgta)
// Button links

.button.orange a {
	font-size: 16px;
	background: none transparent no-repeat;
	border: 2px solid $orange;
	text-shadow: none;
	font-weight: normal;
	letter-spacing: 1px;
	font-family: 'Roboto';
	font-weight: normal;
	line-height: 19px;
	@include trans;
	@include radius4;
	background-color: $orange;
	color: $white !important;
	padding: 15px 20px;
	text-transform: uppercase;
	display: inline-block;

	&:hover{
		background-color: lighten($orange,10%);
		color: $white !important;
		text-decoration: none;
	}
}

.button--orange a {
	font-size: 16px;
	background: none transparent no-repeat;
	border: 2px solid $orange;
	text-shadow: none;
	letter-spacing: 1px;
	font-family: 'Roboto';
	font-weight: 300;
	line-height: 19px;

	@include trans;
	@include radius4;
	background-color: $orange;
	color: $white !important;
	padding: 15px 20px;
	text-transform: uppercase;
	display: inline-block;
	&:hover{
		background-color: lighten($orange,10%);
		color: $white !important;
		text-decoration: none!important;
	}
}


.sgta{
	.button a,
	.button input[type="submit"]{
		font-size: 16px;
		background: none transparent no-repeat;
		border: 2px solid $orange;
		text-shadow: none;
		letter-spacing: 1px;
		font-family: 'Roboto';
		font-weight: 300;
		line-height: 19px;

		@include trans;
		@include radius4;
		background-color: $orange;
		color: $white !important;
		padding: 15px 20px;
		text-transform: uppercase;
		display: block;
		&:hover{
			background-color: lighten($orange,10%);
			color: $white !important;
			text-decoration: none;
		}
	}
	.button.ghost a,
	.button.ghost input[type="submit"]{
		background-color: transparent;
		color: $orange !important;
		border: 2px solid $orange;
		&:hover{
			background-color: $orange;
			color: $white !important;
		}
		&.active{
			background-color: $orange;
			color: $white !important;
			cursor: default;
		}
	}

	.button.blue a,
	.button.blue input[type="submit"]{
		background-color: $blue;
		border: 2px solid $blue;
		color: $white !important;
		&:hover{
			background-color: lighten($blue,10%);
			color: $white !important;
		}
	}

	.button.blue.ghost a,
	.button.blue.ghost input[type="submit"]{
		background-color: transparent;
		color: $blue !important;
		border: 2px solid $blue;
		&:hover{
			background-color: $blue;
			color: $white !important;
		}
		&.active{
			background-color: $blue;
			color: $white !important;
			cursor: default;
		}
	}

	.button.navy a,
	.button.navy input[type="submit"]{
		background-color: $navy;
		border: 2px solid $navy;
		color: $white !important;
		&:hover{
			background-color: lighten($navy,10%);
			color: $white !important;
		}
	}

	.button.navy.ghost a,
	.button.navy.ghost input[type="submit"]{
		background-color: transparent;
		color: $navy !important;
		border: 2px solid $navy;
		&:hover{
			background-color: $navy;
			color: $white !important;
		}
		&.active{
			background-color: $navy;
			color: $white !important;
			cursor: default;
		}
	}

	.button.lgrey a,
	.button.lgrey input[type="submit"]{
		background-color: $lgrey;
		border: 2px solid $lgrey;
		color: $white !important;
		&:hover{
			background-color: darken($lgrey,10%);
			color: $white !important;
		}
	}

	.button.lgrey.ghost a,
	.button.lgrey.ghost input[type="submit"]{
		background-color: transparent;
		color: $lgrey !important;
		border: 2px solid $lgrey;
		&:hover{
			background-color: $lgrey;
			color: $white !important;
		}
		&.active{
			background-color: $lgrey;
			color: $white !important;
			cursor: default;
		}
	}

	.button.grey a,
	.button.grey input[type="submit"]{
		background-color: $grey;
		border: 2px solid $grey;
		color: $white !important;
		&:hover{
			background-color: lighten($grey,10%);
			color: $white !important;
		}
	}

	.button.grey.ghost a,
	.button.grey.ghost input[type="submit"]{
		background-color: transparent;
		color: $grey !important;
		border: 2px solid $grey;
		&:hover{
			background-color: $grey;
			color: $white !important;
		}
		&.active{
			background-color: $grey;
			color: $white !important;
			cursor: default;
		}
	}

	.button.offwhite a,
	.button.offwhite input[type="submit"]{
		background-color: $offwhite;
		border: 2px solid $offwhite;
		color: $white !important;
		&:hover{
			background-color: lighten($offwhite,10%);
			color: $white !important;
		}
	}

	.button.offwhitre.ghost a,
	.button.offwhitre.ghost input[type="submit"]{
		background-color: transparent;
		color: $offwhite !important;
		border: 2px solid $offwhite;
		&:hover{
			background-color: $offwhite;
			color: $white !important;
		}
		&.active{
			background-color: $offwhite;
			color: $white !important;
			cursor: default;
		}
	}

	.button.orange a,
	.button.orange input[type="submit"]{
		background-color: $orange;
		border: 2px solid $orange;
		color: $white;
		&:hover{
			background-color: lighten($orange,10%);
			color: $white;
		}
	}

	.button.orange.ghost a,
	.button.orange.ghost input[type="submit"]{
		background-color: transparent;
		color: $orange !important;
		border: 2px solid $orange;
		&:hover{
			background-color: $orange;
			color: $white !important;
		}
		&.active{
			background-color: $orange;
			color: $white !important;
			cursor: default;
		}
	}

	.button.fullwidth,
	.button.full-width{
		a,
		input[type="submit"]{
			text-align: center;
			display: block;
			width: 100%;
			box-sizing: border-box;
		}
	}


	.button.no-float{
		float: none;
	}

	.button.big{
		a,
		input[type="submit"]{
			font-size: 18px;
		}
	}

	.button.button--white a{
		background-color: transparent;
		color: $orange !important;
		&:hover{
			background-color: $orange;
			color: $white !important;
		}
	}

}

// sgta Edit User profile Styles.
#user-profile-form #edit-actions input[type="submit"]{
	font-size: 16px;
	background: none transparent no-repeat;
	border: 2px solid $orange;
	text-shadow: none;
	font-weight: normal;
	letter-spacing: 1px;
	font-family: 'Roboto';
	font-weight: normal;
	line-height: 19px;

	@include trans;
	@include radius4;
	background-color: $orange;
	color: $white !important;
	padding: 15px 20px;
	text-transform: uppercase;
	display: block;
	&:hover{
		background-color: lighten($orange,10%);
		color: $white !important;
		text-decoration: none;
	}
}

.sgta.manage-ops{
	display: block;
	.ops-trigger{
		@include trans;
		color: transparent;
		font-size: 0;
		height: 30px;
		background: url(../images/icon-gear-black-16.png) no-repeat center center;
		&:hover{
			background: url(../images/icon-gear-orange-16.png) no-repeat center center;
		}
	}
	.item-list{
		ul.manage-ops-links{
			li{
				border: 1px $lgrey solid;
				boder-bottom: 0 none transparent;
				background-color: $offwhite;
				&.first{
					border-radius: 10px 10px 0px 0px;
					-moz-border-radius: 10px 10px 0px 0px;
					-webkit-border-radius: 10px 10px 0px 0px;
				}
				&.last{
					border-bottom: 1px solid $lgrey;
					border-radius: 0px 0px 10px 10px;
					-moz-border-radius: 0px 0px 10px 10px;
					-webkit-border-radius: 0px 0px 10px 10px;
				}
				a{
					padding-left: 35px;
					@include trans;
					&.op-view{
						color: $black !important;
						background: url(../images/icon-view-black-16.png) no-repeat 10px center;
						&:hover{
							color: $orange !important;
							background: url(../images/icon-view-orange-16.png) no-repeat 10px center;
						}
					}
					&.op-manage{
						color: $black !important;
						background: url(../images/icon-manage-black-16.png) no-repeat 10px center;
						&:hover{
							color: $orange !important;
							background: url(../images/icon-manage-orange-16.png) no-repeat 10px center;
						}
					}
					&.op-edit{
						color: $black !important;
						background: url(../images/icon-edit-black-16.png) no-repeat 10px center;
						&:hover{
							color: $orange !important;
							background: url(../images/icon-edit-orange-16.png) no-repeat 10px center;
						}
					}
					&.op-disable{
						color: $black !important;
						background: url(../images/icon-disable-black-16.png) no-repeat 10px center;
						&:hover{
							color: $orange !important;
							background: url(../images/icon-disable-orange-16.png) no-repeat 10px center;
						}
					}
					&.op-enable{
						color: $black !important;
						background: url(../images/icon-enable-black-16.png) no-repeat 10px center;
						&:hover{
							color: $orange !important;
							background: url(../images/icon-enable-orange-16.png) no-repeat 10px center;
						}
					}
                    &.op-ratings{
						color: $black !important;
						background: url(../images/icon-star-black-16.png) no-repeat 10px center;
						&:hover{
							color: $orange !important;
							background: url(../images/icon-star-orange-16.png) no-repeat 10px center;
						}
					}
					&.op-remove{
						color: $black !important;
						background: url(../images/icon-remove-black-16.png) no-repeat 10px center;
						&:hover{
							color: $orange !important;
							background: url(../images/icon-remove-orange-16.png) no-repeat 10px center;
						}
					}
				}
			}
		}
	}
}

#block-sgta-sgta-academy-quicklinks{
	.quick-link{
		a{
			display: block;
            padding: 6px 16px;
            font-size: 16px;
            text-align: left;
            text-transform: uppercase;
            font-family: Ubuntu;
		}
	}
    border-bottom: 1px dotted
}