$white:     #ffffff;
$offwhite:  #f5f5f5;
$grey:      #808080;
$lgrey:     #d1d1d1;
$grey2:     #646464;
$grey3:     #4a4a4a;
$grey4:     #979797;
$grey5:     #ececec;
$grey6:     #858585;
$grey7:     #c2c2c2;
$grey8:     #d8d8d8;
$grey9:     #838383;
$grey10:    #7f7f7f;
$ftrblack:  #333333;
$black:     #222222;
$orange:    #ff582b;
$navy:      #344651;
$blue:      #2b5f77;
$mblue:     #4c7485;
$lblue:     #e2eaee;
$green:     #8eb021;
$red:       #ff0000;


body {
  font-family: 'Roboto' !important;
  font-weight: 300;
  font-size: 14px;
  color: $black;
}
h1,h2 {
  font-size: 36px;
  font-family: 'Ubuntu' !important;
  font-weight: 500;
  line-height: 1.2em;
}
h2.block-title {
  font-weight: 400;
}
h3 {
  font-size: 26px;
  font-family: 'Ubuntu' !important;
  font-weight: 700;
  line-height: 1.2em;
}
h4 {
  font-size: 18px;
  font-family: 'Roboto' !important;
  font-weight: 700;
  line-height: 1.2em;
}
h5,h6 {
  font-family: 'Roboto' !important;
  font-weight: 700;
  line-height: 1.2em;
}
h5 {
  font-size:16px;
}
h6 {
  font-size:15px;
}
h1, h2, h3, h4, h5, h6 {
  letter-spacing: normal;
}
article p {
  font-size: 16px;
  color: $grey2;
  line-height: 1.6em;
}
i,em {
  font-family: 'Roboto';
  font-style: italic;
}
b,strong {
  font-family: 'Roboto';
  font-weight: 700;
  color: $ftrblack;
}
a {
  color: $orange !important;
  &:hover {
    color: $orange;
  }
}
ul.menu li {
  list-style: none;
  list-style-image: none;
}
.content a, a.link{
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.contextual-links, .tabs {
  font-family: sans-serif;
  font-size: 12px;
}
#admin-menu a{
  color: $white !important;
}
.node ol, .node ul {
  padding-left: 1.5em;

  li {
    font-size: 16px;
    color: $grey2;
    line-height: 1.5em;
  }
}


//INCLUDES
@mixin smooth {
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
}
@mixin no-smooth {
  -webkit-font-smoothing: none;
  font-smooth: none;
}
@mixin trans {
  transition-duration: 200ms;
}
@mixin trans-none {
  transition-duration: 0ms;
}
@mixin boxsize {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
@mixin radius100 {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
@mixin radius4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@mixin radius-none {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
@mixin shadow9 {
  -webkit-box-shadow: 0 2px 9px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 9px rgba(0,0,0,0.1);
  box-shadow: 0 2px 9px rgba(0,0,0,0.1);
}
@mixin shadow-none {
  -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
  -moz-box-shadow: 0 0 0 rgba(0,0,0,0);
  box-shadow: 0 0 0 rgba(0,0,0,0);
}
@mixin indent {
  padding-left: 20px;
  border-left: 1px solid $orange;
  margin-bottom: 20px;
  color: $grey;
  max-width: 880px;
}
